import React from 'react';
import { developers1, developers_image, developers_text } from './DevelopersText.module.scss';

const DevelopersText: React.FC = () => {
    return (
        <div className="container d-flex flex-direction-row p-2" data-testid="developers-text-container">
            <div className="row justify-content-center mb-5">
                <h1 className="d-flex justify-content-center">Our Devloping Solutions</h1>
                <img
                    className={`${developers_image} justify-content-center align-items-center`}
                    src="../../images/developers-solutions.png"></img>
                <p className={`${developers_text} d-flex justify-content-center`}>
                    We can provide a solution to any concern you may have with our team of expert developers. It
                    doesn&apos;t matter if it as simple as a website, or a fully functioning online inventory system. We
                    will be ready and willing to help you with whatever concern you may have.
                </p>
                <div className="benefits-list col-lg-4 col-md-8 p-5" data-testid="developers-text-list">
                    <h2>Developers</h2>
                    <p>
                        Every company is a tech company in some way. Our developers know this and can help you to make
                        your company excell online. We know what it takes to make online presences work and will work
                        with you to make it succeed.
                    </p>
                </div>
                <div className="col-lg-8 col-md-4 p-2">
                    <img
                        src="../../images/developers1.jpg"
                        data-testid="developers-text-image"
                        className={`${developers1} img-fluid`}
                        alt="Developer seated at work station with multiple screens"
                    />
                </div>
            </div>
        </div>
    );
};

export default DevelopersText;
