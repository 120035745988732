import React from 'react';
import { developers } from './DevelopersDetails.module.scss';

const CloudDetails: React.FC = () => {
    return (
        <div
            className="developers-details-container d-flex flex-direction-row"
            data-testid="developers-details-container">
            <div className="row d-flex justify-content-center">
                <div className="image col-lg-7 col-md-4 p-2 ml-5">
                    <img
                        src="../../images/developers2.jpg"
                        className={`${developers} img-fluid`}
                        data-testid="developers-details-image"
                        alt="Developer at workstation with multiple screens"></img>
                </div>
                <div className="col-lg-5 col-md-8 p-5" data-testid="developers-details-list">
                    <h2>Developers</h2>
                    <h4>Our Benefits</h4>
                    <ul>
                        <li>Fix and remedy any concerns or blockers you have.</li>
                        <li>Work with you to help you get your project online.</li>
                        <li>Easy to contact and fast.</li>
                        <li>Will make your vision come to life.</li>
                        <li>We have a diverse team for flexible development</li>
                        <li>We have expertise in multiple frameworks</li>
                        <li>Knowledge on the most up to date technologies</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CloudDetails;
