import React from 'react';
import { caret_icon, contact_us_button } from './ContactUsButton.module.scss';

const ContactUsButton: React.FC = () => {
  return (
    <a className={`${contact_us_button}`} href="/contact-us" data-testid="contact-us-button" role="button">
      Get In Touch <i className={`${caret_icon} fa-solid fa-angle-right`}></i>
    </a>
  );
};

export default ContactUsButton;
