import * as React from 'react';
import { Helmet } from 'react-helmet';
import ContactUsButton from '../compontents/ContactUsButton/ContactUsButton';
import HostingDetails from '../compontents/DevelopersDetails/DevelopersDetails';
import HostingText from '../compontents/DevelopersText/DevelopersText';
import Layout from '../compontents/Layout/Layout';
import { contact_us_container } from './developers.module.scss';

type HostingPageProps = {
  location: any;
};

const HostingPage: React.FC<HostingPageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Developers - We Make IT a Reality!</title>
      </Helmet>
      <div className="developers-container" data-testid="developers-container">
        <HostingText></HostingText>
        <HostingDetails></HostingDetails>
      </div>
      <div className={`${contact_us_container} d-flex align-items-center flex-column p-2`}>
        <div>Contact Us now to get your presence online or build your application.</div>
        <div>
          <ContactUsButton />
        </div>
      </div>
    </Layout>
  );
};

export default HostingPage;
